import React, { useState, createContext, useRef, useCallback } from 'react';

import { ModalVideo } from 'design-system';

const ModalContext = createContext({});

const ModalProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalProps, setModalProps] = useState({});

    const modalVideoRef = useRef(null);

    const openModal = (autoplay, props) => {
        setModalProps(props);
        setIsModalOpen(true);

        modalVideoRef?.current?.open(autoplay);
    };

    const closeModal = () => {
        modalVideoRef?.current?.close();
        onCloseModal();
    };

    const onCloseModal = useCallback(() => {
        setIsModalOpen(false);

        setTimeout(() => {
            setModalProps({});
        }, 50);
    }, []);

    return (
        <div>
            <ModalContext.Provider
                value={{
                    isModalOpen,
                    openModal,
                    closeModal,
                }}>
                {children}
                <div>
                    <ModalVideo
                        ref={modalVideoRef}
                        {...modalProps}
                        isModalOpen={isModalOpen}
                        closeModal={closeModal}
                        onCloseModal={onCloseModal}
                    />
                </div>
            </ModalContext.Provider>
        </div>
    );
};

const ModalConsumer = ModalContext.Consumer;

export { ModalContext, ModalProvider, ModalConsumer };
