import React, { useMemo } from 'react';
import cls from 'clsx';
import {
    Row,
    Col,
    Text,
    Heading,
    className,
} from '@solublestudio/react-bootstrap-components';
import Button from './../ButtonComponent';
import CardSources from '../CardSources';

import style from './style.module.scss';

function getColSizeByItems(length) {
    switch (length) {
        case 1:
            return 12;
        case 2:
            return 6;
        case 3:
            return 4;
        case 4:
            return 3;
        default:
            return 12;
    }
}

export default function DescriptionSectionComponent({
    preTitle,
    preTitleTag = 'p',
    title,
    description,
    button,
    buttonSecondary,
    align = 'left',
    descriptions = [],
    classNamePretitle = '',
    classNameTitle = '',
    classNameDescription = '',
    classNameImageTitle = '',
    imageTitle = null,
    ImgTag,
    authors = [],
    date = null,
    headingTag = 'h2',
    isLinkText = false,
    rating,
}) {
    const paragraphs = useMemo(
        () => (description ? [description] : descriptions),
        [description, descriptions],
    );
    const size = useMemo(
        () => getColSizeByItems(paragraphs?.length),
        [paragraphs],
    );

    return (
        <div {...className(align ? `text-${align}` : '')}>
            {imageTitle && (
                <>
                    {imageTitle?.gatsbyImage ? (
                        ImgTag ? (
                            <div
                                {...className(
                                    cls([
                                        classNameImageTitle
                                            ? classNameImageTitle
                                            : '',
                                    ]),
                                )}>
                                <ImgTag
                                    alt={imageTitle?.alt}
                                    image={imageTitle}
                                />
                            </div>
                        ) : null
                    ) : (
                        <div
                            {...className(
                                cls([
                                    style.wrapperImg,
                                    classNameImageTitle
                                        ? classNameImageTitle
                                        : '',
                                ]),
                            )}>
                            <img
                                loading="lazy"
                                alt={imageTitle?.alt}
                                src={imageTitle?.src}
                                {...className('img-fluid')}
                            />
                        </div>
                    )}
                </>
            )}
            {preTitle && (
                <Heading
                    tag={preTitleTag}
                    className={cls([
                        'caption',
                        'text-uppercase',
                        'mb-1',
                        classNamePretitle,
                    ])}>
                    {preTitle}
                </Heading>
            )}
            {title && (
                <Heading
                    mb={{ xs: rating ? 3 : 6 }}
                    tag={headingTag}
                    className={cls(['font-weight-bold', classNameTitle ?? ''])}>
                    {title}
                </Heading>
            )}
            {React.isValidElement(rating) && rating && (
                <div className={style.wrapperRating}>{rating}</div>
            )}
            {paragraphs?.length ? (
                <Row>
                    {paragraphs.map((paragraph, i) => (
                        <Col
                            col={{ xs: 12, lg: size }}
                            key={`paragraph-description-${i}`}
                            className={style.paragraph}>
                            <Text
                                tag={
                                    paragraph.indexOf('<p') !== -1 ? 'div' : 'p'
                                }
                                data-checklist="true"
                                data-link
                                className={cls([
                                    classNameDescription
                                        ? classNameDescription
                                        : '',
                                    align ? `text-${align}` : '',
                                ])}>
                                {paragraph}
                            </Text>
                        </Col>
                    ))}
                </Row>
            ) : null}
            {authors?.length > 0 || date ? (
                <div {...className('mt-5')}>
                    <CardSources authors={authors} date={date} size={32} />
                </div>
            ) : null}
            {(button || buttonSecondary) && (
                <Row
                    mt={{ xs: 4, lg: 8 }}
                    {...className(
                        cls([
                            style.wrapperButton,
                            isLinkText && 'justify-content-center',
                        ]),
                    )}>
                    {button && button.label ? (
                        <Button
                            button={{
                                ...button,
                                className: style.button,
                            }}
                        />
                    ) : null}

                    {buttonSecondary && buttonSecondary.label ? (
                        <Button
                            button={{
                                ...buttonSecondary,
                                className: style.button,
                            }}
                        />
                    ) : null}
                </Row>
            )}
        </div>
    );
}
