import React from 'react';
import cls from 'clsx';

import { className } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

const Icon = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.6667 4.66667C10.6667 6.13943 9.47276 7.33333 8 7.33333C6.52724 7.33333 5.33333 6.13943 5.33333 4.66667C5.33333 3.19391 6.52724 2 8 2C9.47276 2 10.6667 3.19391 10.6667 4.66667ZM9.33333 4.66667C9.33333 5.40305 8.73638 6 8 6C7.26362 6 6.66667 5.40305 6.66667 4.66667C6.66667 3.93029 7.26362 3.33333 8 3.33333C8.73638 3.33333 9.33333 3.93029 9.33333 4.66667Z"
            fill="#14508C"
        />
        <path
            d="M10.6667 10C10.6667 9.63181 10.3682 9.33333 10 9.33333H6C5.63181 9.33333 5.33333 9.63181 5.33333 10V14H4V10C4 8.89543 4.89543 8 6 8H10C11.1046 8 12 8.89543 12 10V14H10.6667V10Z"
            fill="#14508C"
        />
    </svg>
);

export default function Tag({
    label,
    icon,
    disabled,
    infoTag,
    active,
    onClick,
    tag,
    dark,
    ...props
}) {
    const Tag =
        tag &&
        (typeof tag !== 'string' || ['a', 'span', 'button'].includes(tag))
            ? tag
            : 'span';

    return (
        <Tag
            onClick={onClick}
            {...(disabled && {
                disabled: 'disabled',
                'aria-disabled': true,
            })}
            {...props}
            {...className(
                cls([
                    `extraSmall`,
                    props.className ? props.className : '',
                    infoTag ? style.infoTag : style.tag,
                    disabled ? style.disabled : '',
                    active ? style.active : '',
                ]),
            )}>
            {icon ? <Icon /> : null}
            {label}
        </Tag>
    );
}
