import React from 'react';
import cls from 'clsx';
import {
    className,
    SocialIcons as RBCSocialIcons,
} from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

export default function SocialIcons({
    twitterUrl,
    linkedinUrl,
    instagramUrl,
    youtubeUrl,
    colorScheme = 'default',
    padding = 1,
    linkClassName,
    ...otherProps
}) {
    return (
        <RBCSocialIcons
            size={24}
            padding={padding}
            linkClassName={
                className(
                    cls([
                        style.link,
                        colorScheme && style[colorScheme],
                        linkClassName && linkClassName,
                    ]),
                )?.className
            }
            icons={[
                {
                    url: twitterUrl,
                    kind: 'twitter',
                },
                {
                    url: linkedinUrl,
                    kind: 'linkedin',
                },
                {
                    url: instagramUrl,
                    kind: 'instagram',
                },
                {
                    url: youtubeUrl,
                    kind: 'youtube',
                },
            ].filter((i) => !!i.url)}
            {...otherProps}
        />
    );
}
