import React from 'react';

import style from './style.module.scss';

import Tag from '../Tag';
import BaseCard from '../BaseCard';

export default function Categories({
    categories = [],
    onClick = (category) => {},
}) {
    return (
        <BaseCard bodyClassName={style.wrapperCategories}>
            <ul className={style.listOfCategories}>
                {categories &&
                    categories?.length > 0 &&
                    categories.map((category) => {
                        const { id, title, active = false, key } = category;
                        return (
                            <li className={style.wrapperCategory} key={id}>
                                <Tag
                                    label={title}
                                    active={active}
                                    tag="a"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        onClick(category);
                                    }}
                                    href={`#${key}`}
                                />
                            </li>
                        );
                    })}
            </ul>
        </BaseCard>
    );
}
