import React, { useRef, useEffect, useState } from 'react';
import { className } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

export default function HeaderAlert({
    children,
    onClose,
    wrapperClass,
    fixed = true,
}) {
    const [height, setHeight] = useState(null);
    const wrapperRef = useRef();

    useEffect(() => {
        if (
            typeof window === 'undefined' ||
            !wrapperRef ||
            !wrapperRef.current ||
            !fixed
        ) {
            return;
        }

        const setThisHeight = () => {
            setHeight(wrapperRef.current.offsetHeight);
        };

        setTimeout(setThisHeight, 300);
        window.addEventListener('resize', setThisHeight);

        return () => {
            window.removeEventListener('resize', setThisHeight);
        };
    }, [wrapperRef, setHeight, fixed]);

    useEffect(() => {
        if (
            typeof window === 'undefined' ||
            height === null ||
            !fixed ||
            !wrapperRef.current
        ) {
            return;
        }

        const header = window.document.querySelector('#navbar');

        if (header) {
            header.style.transform = `translateY(${height}px)`;
        }

        const menu = window.document.querySelector('#menu');

        if (menu) {
            menu.style.transform = `translateY(${height}px)`;
        }

        const menuMobile = window.document.querySelector('#menu-mobile');

        if (menuMobile) {
            menuMobile.style.transform = `translateY(${height}px)`;
        }

        const main = window.document.querySelector('main');
        if (main) {
            main.style.transform = `translateY(${height}px)`;
            main.style.marginBottom = `${height}px`;
        }

        if (!height) {
            wrapperRef.current.dataset.hide = 'true';
        }

        if (height === 0 && onClose) {
            setTimeout(onClose, 300);
        }
    }, [height, onClose, fixed, wrapperRef]);

    return (
        <div
            id="banner"
            ref={wrapperRef}
            {...className(
                `${
                    fixed ? 'fixed-top' : ''
                }  h4 text-left pt-3 pb-3 pt-lg-2 pb-lg-2 ${
                    style.wrapper
                } ${wrapperClass}`,
            )}>
            <div
                {...className(`medium ${style.content}`)}
                dangerouslySetInnerHTML={{ __html: children }}
            />
            <button
                {...className(`${style.close}`)}
                onClick={() => {
                    setHeight(0);
                }}
            />
        </div>
    );
}
