import React, { useState, useEffect } from 'react';
import {
    className,
    Text,
    Collapse,
    useIsMobile,
} from '@solublestudio/react-bootstrap-components';

import styles from './styles.module.scss';

const IndexIcon = () => (
    <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32.416 17.0001C32.416 8.48675 25.5143 1.58341 16.9993 1.58341C8.48602 1.58341 1.58268 8.48675 1.58268 17.0001C1.58268 25.5134 8.48602 32.4167 16.9993 32.4167C25.5143 32.4167 32.416 25.5134 32.416 17.0001Z"
            stroke="#0096B9"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M22.7852 19.4038L17.0002 13.5938L11.2152 19.4038"
            stroke="#0096B9"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default function TableOfContentsComponent({ data, label, variant }) {
    const [showIndex, setShowIndex] = useState(false);
    const isMobile = useIsMobile();

    const toggleIndex = (e) => {
        if (e) {
            e.preventDefault();
        }

        setShowIndex(!showIndex);
    };

    useEffect(() => {
        if (!isMobile) {
            setShowIndex(true);
        }
    }, [isMobile, setShowIndex]);

    return (
        <>
            {variant === 'boxed' && (
                <Text
                    tag="h3"
                    {...className(
                        `d-none d-lg-block text-primary-t-500 font-weight-bold large mb-4`,
                    )}>
                    {label}
                </Text>
            )}
            <div {...className(`${styles[variant]}`)}>
                <button
                    {...className(
                        `d-lg-none text-primary-t-500 normal ${
                            styles.buttonIndex
                        } ${showIndex ? styles.openedIndex : ''}`,
                    )}
                    onClick={toggleIndex}>
                    {label}
                    <IndexIcon />
                </button>
                <Collapse
                    isOpened={showIndex}
                    theme={{
                        collapse: styles.tableOfContents,
                        content: styles.tableOfContentsBody,
                    }}>
                    {variant === 'boxed' ? (
                        <ol
                            {...className(
                                `mb-0 mt-3 text-primary-t-900 normal ${styles.list}`,
                            )}>
                            {data.map((t, i) => (
                                <li key={t.slug} {...className(`mb-3`)}>
                                    <a
                                        href={`#${t.slug}`}
                                        {...className(`normal ${styles.link}`)}>
                                        {t.title}
                                    </a>
                                </li>
                            ))}
                        </ol>
                    ) : (
                        <ul {...className(`list-unstyled mb-0 mt-3 mt-lg-5`)}>
                            {data.map((t, i) => (
                                <li key={t.slug} {...className(`mb-3`)}>
                                    <a
                                        href={`#${t.slug}`}
                                        {...className(`normal`)}>
                                        {t.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
                </Collapse>
            </div>
        </>
    );
}
