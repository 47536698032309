import React from 'react';
import cls from 'clsx';
import { Card as RBCCard } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

const BaseCard = ({
    imgTop,
    title,
    onClick,
    wrapperClassName,
    bodyClassName,
    children,
    textTag = 'div',
    ...otherProps
}) => {
    return (
        <RBCCard
            imgTop={imgTop ? imgTop : null}
            imgTopAlt={title}
            title={title}
            textTag={textTag}
            onClick={
                onClick
                    ? (ev) => {
                          if (ev) {
                              ev.preventDefault();
                              ev.stopPropagation();

                              onClick();
                          }
                      }
                    : null
            }
            wrapperClassName={cls([
                style.card,
                wrapperClassName ? wrapperClassName : '',
                onClick ? style.clickeable : '',
            ])}
            bodyClassName={cls([
                style.body,
                bodyClassName ? bodyClassName : '',
            ])}
            {...otherProps}>
            {children}
        </RBCCard>
    );
};

export default BaseCard;
