import React, { Fragment, useState } from 'react';
import cls from 'clsx';

import { Heading, Text } from '@solublestudio/react-bootstrap-components';

import {
    className as classNameFnc,
    useIsMobile,
} from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../ButtonComponent';
import Link from '../Link';

import style from './style.module.scss';

const UseCase = ({
    title = '',
    titleTag = 'h2',
    description = '',
    relatedCta,
    readMoreLabel,
    relatedLabel,
    image,
    className,
    imgTag: Image,
}) => {
    const [show, setShow] = useState(false);
    const isMobile = useIsMobile();
    const handleHover = () => {
        setShow(true);
    };

    const handleMouseLeave = () => {
        setShow(false);
    };
    return (
        <div
            className={cls(style.item, className)}
            onMouseEnter={!isMobile ? handleHover : undefined}
            onMouseLeave={!isMobile ? handleMouseLeave : undefined}>
            <div className={style.wrapperContent}>
                <div className={style.wrapperImage}>
                    <Image image={image} alt={title} />
                </div>
                <div {...classNameFnc('pt-3 pb-3 pl-4 pr-4')}>
                    <Heading
                        className={cls([
                            'text-left',
                            'text-primary-t-500',
                            'font-weight-bold',
                            'large',
                        ])}
                        tag={titleTag}>
                        {title}
                    </Heading>

                    {readMoreLabel && (
                        <div {...classNameFnc(`pt-2 ${style.readMore}`)}>
                            <ButtonComponent
                                button={{
                                    type: 'tertiaryColorSecondary',
                                    onClick: (e) => {
                                        e.preventDefault();
                                        setShow(true);
                                    },
                                    label: readMoreLabel,
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div
                className={cls([
                    style.wrapperDescription,
                    show && style.visible,
                ])}>
                <Text
                    tag="div"
                    className={cls(['medium text-light font-weight-medium'])}>
                    {description}
                </Text>
                {relatedCta && relatedCta.length > 0 && (
                    <div {...classNameFnc(cls('mt-6', style.wrapperRelated))}>
                        <Text
                            tag="span"
                            className="normal text-light display-inline font-weight-regular">
                            {relatedLabel}
                        </Text>
                        {relatedCta.map((cta, index) => (
                            <Fragment key={`related-cta-${index}`}>
                                {index !== 0 && (
                                    <Text className="medium text-light font-weight-bold">
                                        ,
                                    </Text>
                                )}
                                <ButtonComponent
                                    button={{
                                        ...cta,
                                        className: cls(
                                            'medium text-light font-weight-bold',
                                            style.link,
                                        ),
                                        Tag: Link,
                                    }}
                                />
                            </Fragment>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default UseCase;
