import React, { forwardRef } from 'react';

import { Breadcrumb as RBCBreadcrumb } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

const Breadcrumb = forwardRef(
    (
        { wrapperClassName, liClassName, linkClassName, links, ...props },
        ref,
    ) => {
        return (
            <RBCBreadcrumb
                ref={ref}
                wrapperClassName={`${wrapperClassName ? wrapperClassName : ''}`}
                liClassName={style.breadcrumbItem}
                linkClassName={style.link}
                links={links}
                {...props}
            />
        );
    },
);

export default Breadcrumb;
