import React, { forwardRef } from 'react';

import { Button } from '@solublestudio/react-bootstrap-components/src/components/Button';

import style from './style.module.scss';
import Link from '../Link';

const Arrow = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7516 21.1485H9V18.8515H25.7516L18.5243 11.6242L20.1485 10L30.1485 20L20.1485 30L18.5243 28.3758L25.7516 21.1485Z"
        />
    </svg>
);

const ButtonComponent = forwardRef(
    ({ button = null, buttons = [], Tag = 'a', href = null }, ref) => {
        let theButtons = button ? [button] : buttons;

        return (
            <>
                {theButtons.map(
                    (
                        {
                            className,
                            block,
                            link,
                            isSubmit,
                            externalLink,
                            target,
                            label,
                            loading,
                            disabled,
                            arrow,
                            size,
                            type,
                            noFollow = false,
                            ...props
                        },
                        i,
                    ) => {
                        const thisTag = isSubmit
                            ? 'button'
                            : Tag === 'button'
                            ? 'button'
                            : Link;

                        return (
                            <Button
                                key={i}
                                ref={ref}
                                block={block ? true : false}
                                type={type}
                                className={`${style[type]} ${className}`}
                                size={size}
                                isSubmit={isSubmit}
                                disabled={disabled}
                                extraAttributes={
                                    noFollow
                                        ? {
                                              rel: 'nofollow',
                                          }
                                        : {}
                                }
                                Tag={thisTag}
                                href={href ?? link?.slug ?? externalLink}
                                target={target ? target : '_self'}
                                {...props}>
                                {type && type.indexOf('tertiary') === 0 ? (
                                    <span className={style.buttonArrow}>
                                        {label}
                                        <Arrow />
                                    </span>
                                ) : (
                                    label
                                )}
                            </Button>
                        );
                    },
                )}
            </>
        );
    },
);

export default ButtonComponent;
