import React from 'react';

import cls from 'clsx';

import { className as classNameFnc } from '@solublestudio/react-bootstrap-components';

import FAQSection from '../FAQ';

import styles from './styles.module.scss';

const ArticleSources = ({ className, items = [] }) => {
    return (
        <section {...classNameFnc(cls(className))}>
            <FAQSection
                answerClassName={styles.content}
                items={items}
                initialShow={null}
                bodyProps={{
                    dataCheckList: false,
                }}
            />
        </section>
    );
};

export default ArticleSources;
