import React, { memo } from 'react';

import { Text } from '@solublestudio/react-bootstrap-components';

import BaseCard from '../BaseCard';
import SocialIcons from '../SocialIcons';

import style from './style.module.scss';

const CardPerson = ({
    imgTop,
    title,
    titleHref,
    role,
    linkedin,
    twitter,
    posts,
    onClick,
    transparent,
    className,
}) => {
    return (
        <BaseCard
            imgTop={imgTop ? imgTop : null}
            imgTopAlt={title}
            imgTopWrapperClassName={style['image-wrapper']}
            title={title}
            titleHref={titleHref}
            titleClassName={`large font-weight-bold text-primary-t-500 ${style.title}`}
            text={role}
            textClassName="caption text-uppercase mb-3 text-primary-b-500"
            onClick={onClick ? onClick : null}
            wrapperClassName={`${style.card} ${
                transparent ? style.transparent : ''
            } ${className ? className : ''}`}
            bodyClassName={style.body}>
            {linkedin || twitter ? (
                <SocialIcons linkedinUrl={linkedin} twitterUrl={twitter} />
            ) : posts ? (
                <Text className="normal">{posts}</Text>
            ) : (
                ''
            )}
        </BaseCard>
    );
};

export default memo(CardPerson);
