import React from 'react';

import { Text } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

const AuthorComponent = ({ name, img, secondLine, tagName }) => {
    const Tag = tagName ?? 'p';

    return (
        <div className={style.author}>
            {img && (
                <span
                    className={style['author-image']}
                    style={
                        img
                            ? {
                                  backgroundImage: `url(${img})`,
                              }
                            : {}
                    }
                />
            )}
            <div>
                <Tag className="small">{name}</Tag>
                <Text tag="p" className="caption text-primary-b-500">
                    {secondLine}
                </Text>
            </div>
        </div>
    );
};

export default AuthorComponent;
