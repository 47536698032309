import React, { memo } from 'react';
import cls from 'clsx';
import { className } from '@solublestudio/react-bootstrap-components';
import { Text } from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../ButtonComponent';
import BaseCard from '../BaseCard';

import style from './style.module.scss';

const CardTestimonialPhoto = ({
    imgTop,
    logo,
    employee,
    role,
    title,
    href,
    text,
    preTitleComponent,
    button,
    onClick,
    wrapperClassName,
    bodyClassName,
    textClassName,
    titleClassName,
    imgTopWrapperClassName,
    colorScheme,
    textTag,
    titleTag,
    video,
}) => {
    return (
        <BaseCard
            imgTop={imgTop ? imgTop : null}
            imgTopAlt={title}
            imgTopWrapperClassName={`${style['image-wrapper']} ${
                imgTopWrapperClassName ? imgTopWrapperClassName : ''
            } ${video && style.iconPlay}`}
            preTitleComponent={
                <>
                    {preTitleComponent ? preTitleComponent : null}
                    {logo ? (
                        <img className={style.logo} src={logo} alt={employee} />
                    ) : null}
                </>
            }
            title={title}
            titleHref={href || null}
            titleTag={titleTag || 'h4'}
            titleClassName={`heading2 font-weight-bold ${style.title} ${
                titleClassName ? titleClassName : ''
            }`}
            textClassName={`normalHeading ${style.text} ${
                textClassName ? textClassName : ''
            }`}
            text={text}
            textTag={textTag}
            onClick={onClick ? onClick : null}
            wrapperClassName={cls([
                style.card,
                wrapperClassName ? wrapperClassName : '',
                colorScheme ? style[colorScheme] : '',
            ])}
            bodyClassName={`${style.body} d-flex flex-column ${
                bodyClassName ? bodyClassName : ''
            }`}>
            {(employee || role) && (
                <div
                    {...className(
                        `${style.employee} d-flex flex-column regular`,
                    )}>
                    {employee && <Text component="p">{employee}</Text>}
                    {role && <Text component="p">{role}</Text>}
                </div>
            )}
            {button ? (
                <div className={style.bottomWrapper}>
                    <div {...className(`pt-3 ${style.button}`)}>
                        <ButtonComponent
                            button={{
                                type:
                                    colorScheme === 'dark'
                                        ? 'tertiaryColorLight'
                                        : 'tertiaryColorSecondary',

                                ...button,
                            }}
                        />
                    </div>
                </div>
            ) : null}
        </BaseCard>
    );
};

export default memo(CardTestimonialPhoto);
