import React from 'react';

import cls from 'clsx';

import { Accordion, Heading } from '@solublestudio/react-bootstrap-components';

import styles from './style.module.scss';

import HTMLTextComponent from '../HTMLTextComponent';

export default function FAQSection({
    items,
    answerClassName,
    bodyProps,
    ...props
}) {
    return (
        <Accordion
            items={
                items?.length
                    ? items.map((i) => ({
                          title: (
                              <Heading
                                  tag="h3"
                                  className={styles.titleClassName}>
                                  {i.question}
                              </Heading>
                          ),
                          className: styles.faqItem,
                          body: (
                              <HTMLTextComponent
                                  text={i.answer}
                                  htmlClassName={answerClassName}
                                  {...bodyProps}
                              />
                          ),
                          ...i,
                      }))
                    : []
            }
            bodyClassName={cls(styles.bodyClassName)}
            openItemClassName={styles.openItemClassName}
            titleClassName={styles.titleClassName}
            {...props}
        />
    );
}
