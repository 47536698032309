import React, { memo } from 'react';

import BaseCard from '../BaseCard';
import AuthorComponent from '../AuthorComponent';

import style from './style.module.scss';

function CardTestimonial({ testimonial, name, role, photo }) {
    return (
        <BaseCard
            wrapperClassName={style.wrapper}
            text={testimonial}
            textTag="blockquote"
            textClassName={`heading2 text-primary-t-500 font-weight-normal mb-3 mb-xxl-5 ${style.text}`}
            bodyClassName={style.body}>
            {name ? (
                <AuthorComponent name={name} img={photo} secondLine={role} />
            ) : null}
        </BaseCard>
    );
}

export default memo(CardTestimonial);
