module.exports = [{
      plugin: require('../../../node_modules/@solublestudio/gatsby-theme-soluble-source/gatsby-browser.js'),
      options: {"plugins":[],"createLocalizedNotFound":false,"primaryLanguage":"en","language":{"en":"","es":"es","de":"de","fr":"fr","pt":"pt"},"pathsByLanguage":{"Podcast":{"en":"podcast","es":"podcast","de":"podcast","fr":"podcast","pt":"podcast"},"BlogPost":{"en":"blog","es":"blog","de":"blog","fr":"blog","pt":"blog"},"Team":{"en":"blog/author","es":"blog/autor","de":"blog/autor","fr":"blog/auteur","pt":"blog/autor"},"Topic":{"en":"blog/category","es":"blog/categoria","de":"blog/kategorie","fr":"blog/categorie","pt":"blog/categoria"},"BlogPostPaginationPage":{"en":"blog/page","es":"blog/pagina","de":"blog/seite","fr":"blog/page","pt":"blog/pagina"},"Webinar":{"en":"resources/webinars","es":"recursos/webinars","de":"ressourcen/webinars","fr":"ressources/webinars","pt":"recursos/webinars"},"GlossaryTerm":{"en":"glossary","es":"glosario","de":"glossar","fr":"glossaire"}},"datocms":{"previewMode":false,"modelPages":["Page","Podcast","BlogPost","Team","Topic","GlossaryTerm"],"cdnHost":"https://assets.ratedpower.com","mediaPath":"41093","modelsPagination":{"BlogPost":{"pagination":{"firstPageItems":7,"perPage":6},"templateKey":"blog-page","sectionKey":"BlogSection","itemsKey":"posts","orderBy":"updatedDate","sort":"DESC"}}},"slices":{"header":"/home/runner/work/mkt-main-website/mkt-main-website/apps/website/src/components/Header/index.tsx","footer":"/home/runner/work/mkt-main-website/mkt-main-website/apps/website/src/components/Footer/index.tsx"},"local":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rated Power","short_name":"Rated Power","start_url":"/","background_color":"#ffffff","theme_color":"#0096b9","display":"minimal-ui","icon":"src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5290cb03e3772374407ac5139f9b28a0"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MDH229V","includeInDevelopment":false,"gtmAuth":"uhuzgbBRZvyrnfkN9hNc-Q","gtmPreview":"env-2","defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.2,"once":true,"rootMargin":"0% 100%"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
