import React from 'react';

import { Text, Heading, className } from '@solublestudio/react-bootstrap-components';
import Tag from '../Tag';

import style from './style.module.scss';

const FeaturedBlogArticles = ({ articles, LinkTag = 'a' }) => {
    if (!LinkTag) {
        LinkTag = 'a';
    }
    
    return (
        <ul {...className(`list-unstyled bg-primary-t-600 shadow ${style.wrapper}`)}>
            {articles.map((a, i) => (
                <li key={i}>
                    <LinkTag href={a.href} title={a.title}>
                        <div {...className(`d-flex justify-content-between align-items-center mb-2 ${style.metas}`)}>
                            <Text className="caption text-uppercase">{a.date}</Text>
                            {a.category ? (
                                <Tag 
                                    className={style.tag}
                                    infoTag={true}
                                    label={a.category}
                                />
                            ) : null}
                        </div>
                        <Heading tag="h4" className="medium font-weight-bold">{a.title}</Heading>
                    </LinkTag>
                </li>
            ))}
        </ul>
    );
};

export default FeaturedBlogArticles;
