import React from 'react';

export default function SpotifyIcon({ fill, ...props }) {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M19.5 3C10.387 3 3 10.387 3 19.5S10.387 36 19.5 36 36 28.613 36 19.5 28.613 3 19.5 3zm7.181 23.946a1.128 1.128 0 01-1.543.405c-2.966-1.733-6.39-2.011-8.743-1.94-2.605.08-4.516.594-4.535.599a1.129 1.129 0 01-.598-2.176c.086-.024 2.142-.582 5.001-.676a22.024 22.024 0 014.818.348c1.916.364 3.664 1.002 5.195 1.897.538.314.72 1.005.405 1.543zm2.098-4.357a1.336 1.336 0 01-1.829.48c-3.512-2.053-7.57-2.382-10.355-2.298-3.086.094-5.35.703-5.372.71a1.337 1.337 0 01-.709-2.578c.103-.028 2.538-.69 5.925-.801 1.994-.066 3.915.073 5.707.413 2.27.43 4.34 1.186 6.153 2.246.637.373.852 1.191.48 1.828zm1.29-3.961c-.282 0-.566-.072-.826-.224-8.35-4.88-19.182-1.979-19.29-1.949a1.64 1.64 0 11-.87-3.161c.124-.035 3.111-.846 7.266-.983 2.447-.08 4.802.09 7 .507 2.784.528 5.323 1.455 7.548 2.755a1.64 1.64 0 01-.829 3.055z"
                fill={fill}
            />
        </svg>
    );
}
