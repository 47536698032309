import React, { forwardRef, useRef, useCallback } from 'react';
import cls from 'clsx';

import {
    className as classNameFnc,
    InputWithEraser,
} from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

const Searcher = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.5 11.7386C3.5 7.18853 7.18853 3.5 11.7386 3.5C16.2886 3.5 19.9771 7.18853 19.9771 11.7386C19.9771 16.2886 16.2886 19.9771 11.7386 19.9771C7.18853 19.9771 3.5 16.2886 3.5 11.7386ZM11.7386 2C6.3601 2 2 6.3601 2 11.7386C2 17.117 6.3601 21.4771 11.7386 21.4771C14.0389 21.4771 16.153 20.6796 17.8193 19.3459L20.9846 22.503C21.2779 22.7955 21.7528 22.7949 22.0453 22.5016C22.3378 22.2083 22.3372 21.7335 22.0439 21.4409L18.9158 18.321C20.5063 16.5876 21.4771 14.2765 21.4771 11.7386C21.4771 6.3601 17.117 2 11.7386 2Z"
            fill="#14508C"
        />
    </svg>
);

const FormInput = forwardRef(({ className, colorScheme, ...props }, ref) => {
    return (
        <InputWithEraser
            ref={ref}
            className={cls(
                style.input,
                colorScheme ? style[colorScheme] : '',
                props.label ? style['with-label'] : '',
                className ? className : '',
            )}
            labelClassName={classNameFnc(`caption ${style.label}`).className}
            {...props}
        />
    );
});

const InputSearcher = forwardRef(
    ({ colorScheme = 'default', ...props }, ref) => {
        const inputRef = useRef();
        const onClickSubmit = useCallback(
            (e) => {
                const theRef = ref?.current ? ref : inputRef;
                if (
                    theRef.current &&
                    !theRef.current.value &&
                    window.document.activeElement !== theRef.current
                ) {
                    e.preventDefault();
                    theRef.current.focus();
                }
            },
            [ref, inputRef],
        );
        return (
            <div {...classNameFnc(`d-flex ${style.inputWithIcon} 4{}`)}>
                <FormInput
                    colorScheme={colorScheme}
                    ref={ref || inputRef}
                    {...props}
                    className={cls(style.inputWithSearcher, props.className)}
                    eraserClassName={style.searcherEraser}
                    wrapperClassName={cls(
                        'flex-grow-1 position-relative',
                        props.wrapperClassName,
                        !props.label ? style.wrapperWithoutLabel : '',
                    )}
                    laterContent={
                        <button
                            onClick={onClickSubmit}
                            type="submit"
                            className={cls(
                                style.searcher,
                                props.size === 'sm' ? style.searcherSmall : '',
                            )}>
                            <Searcher />
                        </button>
                    }
                />
            </div>
        );
    },
);

export default InputSearcher;
