import React, { useMemo } from 'react';
import cls from 'clsx';

import { Link as GatsbyLink } from 'gatsby';
import { navigate as navigateGatsby } from 'gatsby';

export const navigate = (path, target = '_self') => {
    const isInternal = path ? /^\/(?!\/)/.test(path) : false;

    if (isInternal) {
        navigateGatsby(path, { target });
        return;
    }

    window.open(path, target);
};

export default function Link({
    href,
    to: originalTo,
    children,
    download,
    active,
    partiallyActive,
    classes,
    className,
    activeClassName,
    activeLinkClass,
    title,
    titleAlt,
    ...props
}) {
    const [to, internal] = useMemo(() => {
        const path = href ? href : originalTo ? originalTo : null;
        const isInternal = path ? /^\/(?!\/)/.test(path) : false;

        return [path, isInternal];
    }, [href, originalTo]);

    if (internal && !download) {
        return (
            <GatsbyLink
                to={to}
                {...((!!classes?.active ||
                    !!activeClassName ||
                    !!activeLinkClass) && {
                    activeClassName: cls(
                        classes?.active,
                        activeClassName,
                        activeLinkClass,
                    ),
                })}
                target={
                    props?.target ?? props?.openInNewWindow ? '_blank' : '_self'
                }
                className={cls(classes?.root, className)}
                partiallyActive={partiallyActive}
                {...props}>
                {children}
            </GatsbyLink>
        );
    }

    return (
        <a
            data-title={typeof children === 'string' ? children : ''}
            title={typeof children === 'string' ? children : ''}
            aria-label={typeof children === 'string' ? children : ''}
            target={
                props?.target ?? props?.openInNewWindow ? '_blank' : '_self'
            }
            {...(!!active && { active })}
            {...(!!to && { href: to })}
            {...(!!download && { download })}
            {...(!!classes && { classes })}
            {...(!!className && { className })}
            {...props}>
            {children}
        </a>
    );
}
