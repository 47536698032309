import React, { useEffect, memo, useMemo } from 'react';
import cls from 'clsx';
import {
    Row,
    Col,
    Text,
    Heading,
    Container,
    className,
    Section,
} from '@solublestudio/react-bootstrap-components';
import Button from '../ButtonComponent';

import CardTestimonial from './components/CardTestimonial';

import useInViewPort from '../../utils/useInViewPort';

import style from './style.module.scss';

function StepItemSectionImage({
    wrapperClassName,
    image,
    ImgTag,
    imageDirection,
    fitImageToScreen,
    withPadding,
    addMockup,
    linkImage,
    tagLinkImage,
}) {
    const [nodeRef, isVisible] = useInViewPort();
    const isGif = useMemo(() => image.format === 'gif', [image]);
    const isVideo = useMemo(
        () => ['mp4', 'mov', 'webm'].indexOf(image.format) !== -1,
        [image],
    );

    useEffect(() => {
        if (typeof window === 'undefined' || !nodeRef?.current || !isVideo) {
            return;
        }

        let remainingTimes = 10;

        const onSalIn = () => {
            let video = nodeRef.current.querySelector('video[data-play]');
            if (video && !video.dataset.playing) {
                video.dataset.playing = 'true';
                video.play();
            } else if (remainingTimes) {
                setTimeout(onSalIn, 200);
            }

            remainingTimes--;
        };

        nodeRef.current.addEventListener('sal:in', onSalIn);
        return () => {
            if (nodeRef.current) {
                nodeRef.current.removeEventListener('sal:in', onSalIn);
            }
        };
    }, [nodeRef, isVideo]);

    const TagLink = linkImage ? tagLinkImage : 'div';
    const link = linkImage?.href;

    return (
        <div
            ref={nodeRef}
            data-sal="slide-up"
            data-sal-delay="xs-none--lg-400"
            {...className(
                cls([
                    style.containerImage,
                    style[imageDirection],
                    (isGif || isVideo || withPadding) &&
                        style.containerImageBrowser,
                    wrapperClassName && wrapperClassName,
                    fitImageToScreen && style.containerImageFitScreen,
                    withPadding && style.containerImageWithPadding,
                    addMockup && style.mockup,
                ]),
            )}>
            <TagLink href={link}>
                {image?.isGatsby ? (
                    <ImgTag
                        {...className(
                            cls([withPadding && style.imageWithPadding]),
                        )}
                        alt={image?.alt}
                        image={image}
                    />
                ) : (
                    <div
                        className={cls([
                            style.wrapperImg,
                            isVideo &&
                                !isVisible &&
                                style.wrapperVideoInvisible,
                        ])}>
                        {isVisible && (
                            <>
                                {isVideo ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `<video preload="auto" class="${style.video}" loop muted data-play="true" playsinline>
                                <source
                                    src="${image.src}"
                                    type="video/${image.format}"
                                />
                            </video>`,
                                        }}
                                    />
                                ) : (
                                    <img alt={image?.alt} src={image?.src} />
                                )}
                            </>
                        )}
                    </div>
                )}
            </TagLink>
        </div>
    );
}

function StepItemSection({
    preTitle = null,
    preTitleTag = 'p',
    title,
    description,
    button = null,
    image = null,
    logo = null,
    withPadding,
    imageDirection = 'left',
    testimonial,
    ImgTag = null,
    sectionTitle = null,
    fitImageToScreen = false,
    titleTag = 'h2',
    addMockup = false,
    linkImage = null,
    tagLinkImage,
}) {
    const classNameDirection =
        image && imageDirection ? style[`${[imageDirection]}Direction`] : '';

    const TagLink = linkImage ? tagLinkImage : 'div';
    const link = linkImage?.href;

    return (
        <Section
            data-checklist="true"
            className={cls([
                style.section,
                sectionTitle ? style.withSectionTitle : '',
            ])}>
            <Container>
                <Row
                    {...className(
                        cls([
                            classNameDirection,
                            withPadding ? style.rowPadding : '',
                        ]),
                    )}>
                    {sectionTitle && (
                        <Col mb={{ xs: 5, xl: 8 }} data-sal="slide-up">
                            <Text className="heading1 text-primary-t-700">
                                {sectionTitle}
                            </Text>
                        </Col>
                    )}
                    <Col
                        col={{ xs: 12, lg: 5 }}
                        offset={imageDirection === 'left' ? { lg: 7 } : {}}
                        className={`${style.minHeight}`}>
                        <div data-sal="slide-up">
                            {preTitle && (
                                <Text
                                    tag={preTitleTag}
                                    className="caption text-primary-b-500 mb-1 text-uppercase d-block">
                                    {preTitle}
                                </Text>
                            )}
                            {logo && (
                                <ImgTag
                                    className={style.logo}
                                    image={logo}
                                    alt={logo?.alt ?? 'logo'}
                                />
                            )}
                            <Heading
                                className={cls([
                                    sectionTitle ? 'heading2' : 'heading1',
                                    sectionTitle ? 'mb-3' : 'mb-5',
                                    'text-primary-t-500',
                                    'font-weight-bold',
                                ])}
                                tag={titleTag}>
                                {title}
                            </Heading>
                            <Text
                                tag="div"
                                data-checklist="true"
                                data-link
                                className={`medium ${
                                    button ? 'mb-3' : 'mb-8 mb-xxl-10'
                                } ${style.text}`}>
                                {description}
                            </Text>
                            {button && <Button button={button} />}
                        </div>
                        {image && (
                            <TagLink href={link}>
                                <StepItemSectionImage
                                    image={image}
                                    ImgTag={ImgTag}
                                    wrapperClassName={`d-lg-none ${
                                        button ? 'mt-5' : ''
                                    } ${testimonial ? 'mb-8' : ''}`}
                                    imageDirection={imageDirection}
                                    fitImageToScreen={fitImageToScreen}
                                    withPadding={withPadding}
                                />
                            </TagLink>
                        )}
                        {testimonial && (
                            <div data-sal="slide-up">
                                <CardTestimonial
                                    wrapperClassName={cls(style.testimonial)}
                                    testimonial={testimonial?.testimonial}
                                    logo={testimonial?.logo}
                                    employee={testimonial?.employee}
                                    role={testimonial?.role}
                                    ImgTag={ImgTag}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
            {image && (
                <StepItemSectionImage
                    image={image}
                    ImgTag={ImgTag}
                    wrapperClassName="d-none d-lg-block"
                    imageDirection={imageDirection}
                    fitImageToScreen={fitImageToScreen}
                    withPadding={withPadding}
                    addMockup={addMockup}
                    linkImage={linkImage}
                    tagLinkImage={tagLinkImage}
                />
            )}
        </Section>
    );
}

export default memo(StepItemSection);
