import React from 'react';

import BaseCard from '../BaseCard';
import SocialIcons from '../SocialIcons';

import style from './style.module.scss';

export default function CardSocialLinks({
    colorScheme,
    wrapperClassName,
    bodyClassName,
    ...SocialIconsProps
}) {
    return (
        <BaseCard
            wrapperClassName={`${style.wrapperCard} ${
                wrapperClassName ? wrapperClassName : ''
            }`}
            bodyClassName={bodyClassName ? bodyClassName : ''}>
            <SocialIcons
                {...SocialIconsProps}
                padding={0}
                colorScheme={colorScheme}
                wrapperClassName={style.wrapper}
            />
        </BaseCard>
    );
}
