import React from 'react';

import { Pagination as RBCPagination } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

const Pagination = props => {
    return (
        <RBCPagination
            wrapperClassName={style.wrapper}
            pageClassName={style.page}
            disabledClassName={style.disabled}
            arrowClassName={style.arrow}
            activeClassName={style.active}
            arrowSvg={(
                <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.263 0.712584C8.54645 0.996029 8.54645 1.45558 8.263 1.73903L2.00203 8L8.263 14.261C8.54645 14.5444 8.54645 15.004 8.263 15.2874C7.97956 15.5709 7.52 15.5709 7.23656 15.2874L0.462364 8.51322C0.178919 8.22978 0.178919 7.77022 0.462364 7.48678L7.23656 0.712584C7.52 0.429139 7.97956 0.429139 8.263 0.712584Z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default Pagination;