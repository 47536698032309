import React from 'react';

import { Text, className } from '@solublestudio/react-bootstrap-components';

import Button from '../ButtonComponent';

const Steps = ({ text, onClick = () => {}, total, current = 0 }) => {
    return current > 0 ? (
        <div {...className('d-flex align-items-center')}>
            <Button
                button={{
                    Tag: 'button',
                    isSubmit: false,
                    label: (
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.94236 6L3.55779 11.3846C3.2179 11.7245 3.2179 12.2755 3.55779 12.6154L8.94236 18L10.1732 16.7691L6.27443 12.8704H20.1296C20.6103 12.8704 21 12.4807 21 12C21 11.5193 20.6103 11.1297 20.1296 11.1297H6.27443L10.1732 7.23086L8.94236 6Z"
                                fill="#061A2D"
                            />
                        </svg>
                    ),
                    onClick: (ev) => {
                        ev.preventDefault();
                        if (typeof onClick === 'function') {
                            onClick();
                        }
                    },
                }}
            />
            <Text tag="span" className="ml-1 small">
                {`${text} ${current}/${total}`}
            </Text>
        </div>
    ) : null;
};

export default Steps;
