import React, { memo } from 'react';
import cls from 'clsx';
import { className } from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../ButtonComponent';
import Tag from '../Tag';
import CardSources from '../CardSources';
import BaseCard from '../BaseCard';

import style from './style.module.scss';

const Card = ({
    imgTop,
    title,
    href,
    text,
    date,
    updatedLabel,
    updatedDate,
    authors = [],
    category,
    preTitleComponent,
    button,
    onClick,
    wrapperClassName,
    bodyClassName,
    textClassName,
    titleClassName,
    horizontal,
    imgTopWrapperClassName,
    withLine = true,
    colorScheme,
    textTag,
    titleTag,
    bottomWrapperClassName,
    withoutPreset = false,
}) => {
    return (
        <BaseCard
            imgTop={imgTop ? imgTop : null}
            imgTopAlt={title}
            imgTopWrapperClassName={`${style['image-wrapper']} ${
                imgTopWrapperClassName ? imgTopWrapperClassName : ''
            }`}
            preTitleComponent={
                <>
                    {preTitleComponent ? preTitleComponent : null}
                    {category ? (
                        <Tag label={category} infoTag={true} className="mb-3" />
                    ) : null}
                </>
            }
            title={title}
            titleHref={href || null}
            titleTag={titleTag || 'h4'}
            titleClassName={`${!withoutPreset && 'medium'} font-weight-bold ${
                style.title
            } ${titleClassName ? titleClassName : ''}`}
            textClassName={`${!withoutPreset && 'normal'}  mb-3 ${style.text} ${
                textClassName ? textClassName : ''
            }`}
            text={text}
            textTag={textTag}
            onClick={onClick ? onClick : null}
            wrapperClassName={cls([
                style.card,
                wrapperClassName ? wrapperClassName : '',
                horizontal ? style.horizontal : '',
                colorScheme ? style[colorScheme] : '',
            ])}
            bodyClassName={`${style.body} d-flex flex-column ${
                bodyClassName ? bodyClassName : ''
            }`}>
            {button || authors?.length > 0 || date ? (
                <div className={`${style.bottomWrapper}`}>
                    {authors?.length > 0 || date ? (
                        <CardSources
                            authors={authors}
                            date={date}
                            updatedLabel={updatedLabel}
                            updatedDate={updatedDate}
                            colorScheme={colorScheme}
                        />
                    ) : null}
                    {button ? (
                        <div
                            {...className(
                                `pt-3 ${!withLine ? style.hideLine : ''} ${
                                    style.button
                                }  ${
                                    bottomWrapperClassName
                                        ? bottomWrapperClassName
                                        : ''
                                }`,
                            )}>
                            <ButtonComponent
                                button={{
                                    type:
                                        colorScheme === 'dark'
                                            ? 'tertiaryColorLight'
                                            : 'tertiaryColorSecondary',
                                    block: !withLine ? false : true,
                                    ...button,
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            ) : null}
        </BaseCard>
    );
};

export default memo(Card);
