import React, { memo } from 'react';

import Card from '../Card';

import style from './style.module.scss';

import { className } from '@solublestudio/react-bootstrap-components';

import useInViewPort from '../../utils/useInViewPort';

const CardPodcast = ({ episodeId, ...props }) => {
    const [nodeRef, isVisible] = useInViewPort();

    return (
        <Card
            {...props}
            preTitleComponent={
                <div
                    ref={nodeRef}
                    {...className(
                        props?.category || props?.title ? 'mb-3' : '',
                    )}>
                    {isVisible && (
                        <iframe
                            src={`https://widget.spreaker.com/player?episode_id=${episodeId}&theme=light&playlist=false&playlist-continuous=false&autoplay=false&live-autoplay=false&chapters-image=true&episode_image_position=right&hide-logo=false&hide-likes=false&hide-comments=false&hide-sharing=false&hide-download=true`}
                            width="100%"
                            height="80px"
                            frameBorder="0"
                        />
                    )}
                </div>
            }
        />
    );
};

export default memo(CardPodcast);
