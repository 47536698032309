import React from 'react';

export default function IvooxIcon({ fill, ...props }) {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M17.579 35.703c-6.32-.921-12.814-6.56-13.516-14.722-.812-9.61 6.32-16.894 14.371-17.86 9.72-1.14 17.4 5.924 18.43 14.218 1.185 9.544-5.572 17.026-13.34 18.386 0-.132-.021-.241-.021-.373V22.65c0-2.304.021-4.608.021-6.934 0-.746-.197-1.426-.855-1.799a2.882 2.882 0 00-1.273-.395c-2.918-.044-5.836-.022-8.732-.022-.307 0-.395.11-.395.417.022 1.668.022 3.335 0 5.003 0 .307.088.395.395.395 1.448-.022 2.896 0 4.344 0 .505 0 .505 0 .505.526 0 5.156.022 10.312.022 15.446.044.154.044.286.044.417zm2.742-23.52c1.865 0 3.313-1.426 3.313-3.291A3.268 3.268 0 0020.365 5.6c-1.865 0-3.313 1.426-3.313 3.269-.022 1.865 1.404 3.313 3.27 3.313z"
                fill={fill}
            />
        </svg>
    );
}
