import React, { memo } from 'react';

import BaseCard from '../BaseCard';

import style from './style.module.scss';

export default memo(function CardResource({
    title,
    colorScheme = 'default',
    href,
    onClick,
}) {
    return (
        <BaseCard
            bodyClassName={`${style.body}`}
            wrapperClassName={`${style.wrapper} ${
                colorScheme ? style[colorScheme] : ''
            }`}
            titleClassName={`${style.text} ${
                colorScheme ? style[colorScheme] : ''
            }`}
            title={title}
            titleHref={href}
            onClick={onClick}
        />
    );
});
