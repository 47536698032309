import React from 'react';

export default function AppleIcon({ fill, ...props }) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M19.4705 6.55781C12.5022 6.55781 6.86719 12.2076 6.86719 19.1685C6.86719 26.1295 12.5022 31.7866 19.4705 31.7866C26.4388 31.7866 32.0812 26.1368 32.0812 19.1759C32.0812 12.2076 26.4315 6.55781 19.4705 6.55781ZM25.3192 24.2364C25.0835 25.2382 24.3248 25.798 23.3083 25.9895C22.417 26.1516 21.673 26.1958 20.9585 25.6212C20.0893 24.8846 20.0746 23.6766 20.8554 22.9105C21.4741 22.3212 22.3507 22.2033 23.6545 21.9676C23.8754 21.9308 24.067 21.8792 24.2217 21.6951C24.4574 21.4299 24.3837 21.5478 24.3837 15.7433C24.3837 15.3308 24.1848 15.2203 23.765 15.294C23.4703 15.3455 16.9955 16.5536 16.9955 16.5536C16.6272 16.6346 16.502 16.7451 16.502 17.165C16.502 25.717 16.5388 25.3266 16.4136 25.8937C16.2589 26.5567 15.8538 27.0576 15.3161 27.3375C14.7047 27.6763 13.5924 27.8237 13.0031 27.7205C11.4268 27.4259 10.8743 25.6065 11.9424 24.5605C12.5612 23.9712 13.4377 23.8533 14.7415 23.6176C14.9625 23.5808 15.154 23.5292 15.3087 23.3451C15.677 22.9252 15.375 13.9902 15.5002 13.4967C15.5297 13.3051 15.6107 13.1431 15.758 13.0252C15.9127 12.9 16.1853 12.8263 16.2516 12.8263C23.6913 11.4268 24.5973 11.25 24.7299 11.25C25.1498 11.2205 25.3929 11.471 25.3929 11.8908C25.3855 24.4574 25.4223 23.7797 25.3192 24.2364ZM28.4277 3H10.5723C6.38103 3 3 6.38103 3 10.5723V28.4277C3 32.619 6.38103 36 10.5723 36H28.4277C32.6263 36 36 32.619 36 28.4277V10.5723C36 6.38103 32.619 3 28.4277 3ZM19.4705 33.3482C11.6404 33.3482 5.29085 26.9987 5.29085 19.1685C5.29085 11.3384 11.6404 4.98884 19.4705 4.98884C27.3007 4.98884 33.6502 11.3384 33.6502 19.1685C33.6502 26.9987 27.3007 33.3482 19.4705 33.3482Z" />
        </svg>
    );
}
