import React, { useMemo } from 'react';
import cls from 'clsx';

import { className } from '@solublestudio/react-bootstrap-components';

import checkImage from '../../img/check.svg';

import style from './styles.module.scss';

export default function TableBlock({
    cols,
    content,
    headerClassName,
    animate,
    TitleTag = 'span',
    wrapperClassName,
}) {
    const firstIsTitle = useMemo(() => cols?.length && !cols[0].trim(), [cols]);

    return (
        <div role="table" {...className(wrapperClassName)}>
            <div
                {...className(cls([headerClassName, 'bg-primary-b-50']))}
                role="rowgroup">
                <div {...className(cls(['d-flex', style['row']]))} role="row">
                    {cols.map((col, i) => (
                        <TitleTag
                            key={i}
                            {...className(
                                cls([
                                    'pt-2 pb-2 pr-1',
                                    i === 0 ? 'pl-2' : 'pl-1',
                                    firstIsTitle && 'text-center',
                                    'font-weight-bold',
                                    style['row-content'],
                                ]),
                            )}
                            role="columnheader">
                            {col}
                        </TitleTag>
                    ))}
                </div>
            </div>
            {content.map((group, i) => (
                <div
                    key={i}
                    {...className(
                        cls([style['rowgroup'], style['row-content']]),
                    )}
                    {...(!!animate && {
                        'data-sal': 'slide-up',
                        'data-sal-delay': 'xs-none--lg-100',
                    })}
                    role="rowgroup">
                    {group.map((row, j) =>
                        row.length === 1 && cols.length > 1 ? (
                            <div
                                key={j}
                                {...className(
                                    cls([
                                        'p-2',
                                        'font-weight-bold',
                                        style['cell-title'],
                                    ]),
                                )}>
                                {group[0]}
                            </div>
                        ) : (
                            <div
                                key={j}
                                {...className(cls('d-flex', style['row']))}
                                role="row">
                                {row.map((cell, k) => (
                                    <div
                                        key={k}
                                        {...className(
                                            cls([
                                                'pt-2 pb-2 pr-1',
                                                k === 0 ? 'pl-2' : 'pl-1',
                                                firstIsTitle && k !== 0
                                                    ? 'text-center'
                                                    : style.left,
                                                style['cell-decoration'],
                                            ]),
                                        )}
                                        {...(cell === 'x'
                                            ? {
                                                  children: (
                                                      <img
                                                          src={checkImage}
                                                          alt=""
                                                      />
                                                  ),
                                              }
                                            : cell && cell !== 'false'
                                            ? {
                                                  dangerouslySetInnerHTML: {
                                                      __html: cell,
                                                  },
                                              }
                                            : {})}
                                        role="cell"
                                    />
                                ))}
                            </div>
                        ),
                    )}
                </div>
            ))}
        </div>
    );
}
