import React from 'react';
import cls from 'clsx';

import { Text } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

export default function IconTextComponent({
    title,
    description,
    icon,
    dark,
    className,
    textClassName,
    horizontal,
    stylizedList = false,
    size = 'big',
    isCard = false,
    ...props
}) {
    const isSmall = size === 'small';

    return (
        <div
            className={cls(
                className,
                horizontal
                    ? style.iconTextHorizontalWrapper
                    : style.iconTextWrapper,
                isCard && style.wrapperCardContent,
            )}
            {...props}>
            <div
                className={cls(
                    !isCard && dark ? style.IconShapeDark : '',
                    style.IconShape,
                    isSmall ? style.small : '',
                    isCard && style.IconShapeLight,
                )}>
                {icon ? (
                    typeof icon === 'string' ? (
                        <img src={icon} alt={title} />
                    ) : (
                        icon
                    )
                ) : null}
            </div>
            <div className={cls(textClassName, style.text)}>
                <Text
                    tag="p"
                    className={cls(
                        'text-primary-t-500 font-weight-bold mb-1',
                        isCard ? 'heading2' : isSmall ? 'small' : 'large',
                        isCard && style.titleCard,
                    )}>
                    {title}
                </Text>
                <Text
                    data-checklist={stylizedList}
                    tag="div"
                    className={cls(
                        isSmall ? 'small' : 'normal',
                        isCard && style.description,
                    )}>
                    {description}
                </Text>
            </div>
        </div>
    );
}
