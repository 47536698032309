import { useEffect, useState } from 'react';

const layoutBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1275,
    xxl: 1435,
};

function useMediaQuery(breakpoint) {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(
            `(min-width: ${layoutBreakpoints[breakpoint]}px)`,
        );
        const handler = () => setMatches(media.matches);
        media.addEventListener('change', handler);
        handler();
        return () => media.removeEventListener('change', handler);
    }, [breakpoint]);

    return matches;
}

export default useMediaQuery;
