import React from 'react';
import cls from 'clsx';
import styles from './styles.module.scss';

const StepProgress = ({ step, totalSteps, className }) => {
    return (
        <div className={styles.positionBar}>
            <div className={cls([styles.wrapper, className])}>
                <div
                    className={styles.bar}
                    style={{
                        width:
                            step === 0
                                ? '10%'
                                : `${(step / totalSteps) * 100}%`,
                    }}
                />
            </div>
        </div>
    );
};

export default StepProgress;
