import React from 'react';

import AppleIcon from './components/AppleIcon';
import IvooxIcon from './components/IvooxIcon';
import SpotifyIcon from './components/SpotifyIcon';

import style from './style.module.scss';

export default function PodcastLinks({ spotifyUrl, ivooxUrl, appleUrl }) {
    return (
        <div className={style.containerLinks}>
            {spotifyUrl && (
                <a
                    target="_blank"
                    href={spotifyUrl}
                    title="spotify"
                    className={style.wrapperLink}>
                    <SpotifyIcon />
                </a>
            )}
            {ivooxUrl && (
                <a
                    target="_blank"
                    href={ivooxUrl}
                    title="ivoox"
                    className={style.wrapperLink}>
                    <IvooxIcon />
                </a>
            )}
            {appleUrl && (
                <a
                    target="_blank"
                    href={appleUrl}
                    title="apple music"
                    className={style.wrapperLink}>
                    <AppleIcon />
                </a>
            )}
        </div>
    );
}
