import React from 'react';
import cls from 'clsx';
import style from './style.module.scss';

import {
    Accordion,
    Container,
    Row,
    Col,
    className,
} from '@solublestudio/react-bootstrap-components';

import TableBlock from '../TableBlock';

export default function PricingDetails({
    toggleButtonOpen,
    toggleButtonClose,
    cols,
    content,
    openedByDefault = false,
}) {
    return (
        <Container pb={{ xs: 4, lg: 8 }}>
            <Row>
                <Col col={{ xs: 12 }}>
                    <Accordion
                        initialShow={openedByDefault ? 0 : null}
                        openItemClassName={
                            className(cls([style['accordion-opened']]))
                                .className
                        }
                        titleClassName={
                            className(
                                cls([style['accordion-title'], 'bg-white']),
                            ).className
                        }
                        bodyClassName={
                            className(cls([style['sticky-context'], 'mt-8']))
                                .className
                        }
                        items={[
                            {
                                title: (
                                    <div
                                        {...className(
                                            cls([
                                                'text-center',
                                                'text-secondary-500',
                                                'font-weight-bold',
                                                'pt-2',
                                                'pb-2',
                                            ]),
                                        )}
                                        data-text-closed={toggleButtonOpen}
                                        data-text-opened={toggleButtonClose}
                                    />
                                ),
                                body: (
                                    <TableBlock
                                        cols={cols}
                                        content={content}
                                        headerClassName={style['sticky-bar']}
                                        animate
                                        TitleTag="h4"
                                    />
                                ),
                            },
                        ]}
                    />
                </Col>
            </Row>
        </Container>
    );
}
