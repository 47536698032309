exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-topic-js": () => import("./../../../src/templates/blog-topic.js" /* webpackChunkName: "component---src-templates-blog-topic-js" */),
  "component---src-templates-glossary-term-js": () => import("./../../../src/templates/glossary-term.js" /* webpackChunkName: "component---src-templates-glossary-term-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-podcast-js": () => import("./../../../src/templates/podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */),
  "slice---src-components-footer-index-tsx": () => import("./../../../src/components/Footer/index.tsx" /* webpackChunkName: "slice---src-components-footer-index-tsx" */),
  "slice---src-components-header-index-tsx": () => import("./../../../src/components/Header/index.tsx" /* webpackChunkName: "slice---src-components-header-index-tsx" */)
}

