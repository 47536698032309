import React from 'react';

import BaseCard from '../../../BaseCard';

import { Text, className } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

export default function CardTestimonial({
    wrapperClassName,
    testimonial,
    logo,
    employee,
    role,
    ImgTag = null,
}) {
    return (
        <BaseCard
            wrapperClassName={`${style.wrapper} ${
                wrapperClassName ? wrapperClassName : ''
            }`}
            bodyClassName={style.body}
            text={testimonial}
            textTag="div"
            textClassName={`normal text-primary-b-500 ${style.textClassName}`}>
            <div className={style.childrenClassName}>
                <div className={style.logoCompany}>
                    {logo ? (
                        ImgTag && logo.isGatsby ? (
                            <ImgTag alt={logo?.alt} image={logo} />
                        ) : (
                            <img
                                loading="lazy"
                                alt={logo?.alt}
                                src={logo?.src}
                                {...className('img-fluid')}
                            />
                        )
                    ) : null}
                </div>
                <Text
                    className="medium text-secondary-500 font-weight-bold mb-1"
                    tag="p">
                    {employee}
                </Text>
                <Text className="small text-primary-b-500">{role}</Text>
            </div>
        </BaseCard>
    );
}
