import React from 'react';
import cls from 'clsx';
import {
    Container,
    Section,
    Row,
    Col,
    Heading,
    Text,
} from '@solublestudio/react-bootstrap-components';

import Button from '../ButtonComponent';

import Link from '../Link';

export default function ResourceContentSection({
    title,
    titleTag,
    description,
    viewAll,
    content,
    light,
}) {
    return (
        <Section>
            <Container>
                <Row>
                    <Col
                        col={{ xs: 12, lg: !viewAll ? 6 : 5 }}
                        offset={{ lg: !viewAll ? 3 : 0 }}>
                        <Heading
                            className={cls([
                                'mb-3',
                                !viewAll ? 'text-center' : 'text-left',

                                light ? 'text-light' : 'text-primary-t-700',
                                'font-weight-bold',
                                'heading1',
                            ])}
                            tag={titleTag}>
                            {title}
                        </Heading>
                        <Text
                            data-checklist="true"
                            className={cls([
                                'mb-4',
                                !viewAll ? 'text-center' : 'text-left',
                                light ? 'text-light' : '',
                                'normal',
                            ])}
                            tag="div">
                            {description}
                        </Text>
                    </Col>
                    {viewAll && (
                        <Col col={{ xs: 12 }} mb={{ xs: 5 }}>
                            <Button
                                button={{
                                    type: light
                                        ? 'tertiaryColorLight'
                                        : 'tertiaryColorSecondary',
                                    ...viewAll,
                                    Tag: Link,
                                }}
                            />
                        </Col>
                    )}
                    {content}
                </Row>
            </Container>
        </Section>
    );
}
