import React from 'react';

import { Text } from '@solublestudio/react-bootstrap-components';

import cls from 'clsx';

import Card from '../Card';

import Link from '../Link';

import useMediaQuery from '../../hooks/useMediaQuery';

import styles from './styles.module.scss';

const BannerBlock = ({ title, text, cta, imgTop }) => {
    const isXL = useMediaQuery('xl');

    return (
        <Card
            horizontal={isXL}
            title={title}
            imgTop={imgTop}
            imgTopWrapperClassName={cls(styles['image-wrapper'])}
            text={text}
            withoutPreset={true}
            titleClassName={`heading2 ${styles.title}`}
            bodyClassName={styles.card}
            textClassName={cls(styles.text, 'medium')}
            bottomWrapperClassName={styles.bottom}
            button={
                cta
                    ? {
                          label: cta?.label,
                          href: cta?.href,
                          Tag: Link,
                      }
                    : null
            }
            withLine={false}
        />
    );
};

export default BannerBlock;
