import React, {
    forwardRef,
    useEffect,
    useState,
    useRef,
    useImperativeHandle,
    useCallback,
} from 'react';
import { Modal, Text } from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../ButtonComponent';

import styles from './style.module.scss';

const ModalVideo = forwardRef(function ModalVideo(
    {
        description,
        cta,
        videoUrl,
        thumbnail,
        closeModalLabel,
        alternativeCtaLabel,
        autoplay = false,
        onCloseModal,
        closeModal,
        isModalOpen,
        ...props
    },
    ref,
) {
    const [played, setPlayed] = useState(autoplay);
    const [mounted, setMounted] = useState(false);
    const [isEnded, setIsEnded] = useState(false);
    const [firstOpen, setFirstOpen] = useState(false);

    const modalRef = useRef();
    const player = useRef(null);

    const thisOnClose = useCallback(
        (ev) => {
            if (player?.current) {
                player.current.pauseVideo();
            }

            if (typeof onClose === 'function') {
                onCloseModal(ev);
            }
        },
        [onCloseModal],
    );

    useEffect(() => {
        if (typeof document === 'undefined' || !isModalOpen) return;

        function onPressEscape(ev) {
            ev?.preventDefault();
            if (ev.key === 'Escape') {
                closeModal();
            }
        }
        document.addEventListener('keydown', onPressEscape);

        return () => {
            if (typeof document === 'undefined' || !isModalOpen) return;
            document.removeEventListener('keydown', onPressEscape);
        };
    }, [thisOnClose, isModalOpen]);

    useImperativeHandle(
        ref,
        () => ({
            open: (autoplay = false) => {
                setPlayed(autoplay);

                modalRef?.current?.toggle();
                setFirstOpen(true);
            },
            close: (autoplay = false) => {
                modalRef?.current?.toggle();
            },
        }),
        [],
    );

    useEffect(() => {
        if (typeof window !== 'undefined' && modalRef?.current && firstOpen) {
            if (!window.YT) {
                let scr = document.createElement('script'),
                    head =
                        document.head ||
                        document.getElementsByTagName('head')[0];
                scr.src = 'https://www.youtube.com/iframe_api';

                head.insertBefore(scr, head.firstChild);

                window.onYouTubeIframeAPIReady = () => {
                    setMounted(true);
                };
                return;
            }

            setMounted(true);
        }
    }, [firstOpen]);

    useEffect(() => {
        if (typeof window === 'undefined' || !mounted || !videoUrl) return;

        if (!player?.current) {
            player.current = new window.YT.Player('modal-player', {
                videoId: videoUrl.split('v=')[1],
                height: '720',
                width: '1280',
                playerVars: {
                    rel: 0,
                    cc_load_policy: 1,
                    autoplay: played ? 1 : 0,
                },
                events: {
                    onStateChange: (event) => {
                        if (event.data == YT.PlayerState.ENDED) {
                            setIsEnded(true);
                        }
                    },
                },
            });
        }

        if (
            player?.current &&
            typeof player?.current?.loadVideoById === 'function'
        ) {
            player?.current?.loadVideoById({
                videoId: videoUrl.split('v=')[1],
                startSeconds: 0,
                endSeconds: 0,
            });
        }
    }, [mounted, played, videoUrl]);

    return (
        <Modal
            {...props}
            onToggle={(value) => (!value ? thisOnClose() : null)}
            ref={modalRef}
            header={
                <button
                    type="button"
                    data-dismiss="modal"
                    onClick={closeModal}
                    className={styles.closeButton}
                    aria-label="Close">
                    <span></span>
                    {closeModalLabel ?? 'Close'}
                    <span className={styles.cross}>×</span>
                </button>
            }
            bodyClassName={styles.body}
            dialogClassName={styles.dialog}
            headerClassName={styles.header}
            footerClassName={styles.footer}
            showClassName={styles.show}
            wrapperClassName={styles.wrapper}
            closeButtonClassName={styles.modalClose}
            contentClassName={styles.content}
            footer={
                <>
                    {description && (
                        <Text
                            tag="div"
                            className={[
                                'large text-light pb-2 pb-lg-0',
                                styles.description,
                            ].join(' ')}>
                            {description}
                        </Text>
                    )}

                    {cta && (
                        <ButtonComponent
                            button={{
                                ...cta,
                                onClick: closeModal,
                                type: isEnded
                                    ? 'primaryColorSecondary'
                                    : 'secondaryColorSecondary',
                                label: isEnded
                                    ? alternativeCtaLabel ?? cta?.label
                                    : cta?.label,
                            }}
                        />
                    )}
                </>
            }>
            <div className={styles.videoContainer}>
                {!played && (
                    <div
                        className={styles.videoCover}
                        style={{
                            backgroundImage: `url(${
                                thumbnail?.file?.childImageSharp?.fluid?.src ??
                                thumbnail
                            })`,
                        }}>
                        <button
                            className={styles.playButton}
                            onClick={(ev) => {
                                ev.preventDefault();
                                if (player?.current) {
                                    setPlayed(true);
                                    player?.current?.playVideo();
                                }
                            }}
                        />
                    </div>
                )}
                <div id="modal-player" />
            </div>
        </Modal>
    );
});

export default ModalVideo;
