import React, { memo } from 'react';
import cls from 'clsx';
import { Text, className } from '@solublestudio/react-bootstrap-components';
import style from './style.module.scss';

const CardSources = ({
    authors,
    reviewers,
    date,
    updatedLabel,
    updatedDate,
    colorScheme,
    size = 16,
}) => {
    return (
        (date || authors) && (
            <div className={cls([style.sources, style[colorScheme] ?? ''])}>
                {date && (
                    <Text
                        className={`caption text-uppercase ${style.date} ${
                            authors?.length ? style['with-authors'] : ''
                        }`}
                        tag="span">
                        {date}
                    </Text>
                )}
                {updatedDate && updatedDate !== date && (
                    <Text
                        className={`caption text-uppercase ${style.date} ${
                            authors?.length ? style['with-authors'] : ''
                        }`}
                        tag="span">
                        {`${updatedLabel} ${updatedDate}`}
                    </Text>
                )}
                {authors?.length > 0 &&
                    authors.map(({ img, name, Tag = 'span' }, index) => {
                        return (
                            <Tag
                                key={index}
                                {...className(
                                    `caption ${style.author} ${
                                        authors?.length
                                            ? style['with-authors']
                                            : ''
                                    }`,
                                )}>
                                {img && (
                                    <span
                                        className={style['author-image']}
                                        style={
                                            img
                                                ? {
                                                      height: size,
                                                      width: size,
                                                      backgroundImage: `url(${img})`,
                                                  }
                                                : {}
                                        }
                                    />
                                )}
                                {name}
                            </Tag>
                        );
                    })}
            </div>
        )
    );
};

export default memo(CardSources);
