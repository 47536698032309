import React from 'react';
import {
    Text,
    Heading,
    Image,
    Col,
    Container,
    Row,
    Section,
} from '@solublestudio/react-bootstrap-components';

import BaseCard from '../BaseCard';

import styles from './style.module.scss';

const CardFormSection = ({
    title,
    description,
    titleTag,
    image,
    form,
    imageTag: ImageTag = Image,
}) => {
    return (
        <Section>
            <Container>
                <BaseCard
                    bodyClassName={styles.body}
                    wrapperClassName={styles.wrapper}>
                    <Row>
                        <Col col={{ xs: 12, md: 6, lg: 5 }}>
                            <div className={styles.wrapperImage}>
                                {image ? (
                                    <ImageTag
                                        className={styles.imageWrapper}
                                        image={image}
                                        alt={image?.alt}
                                    />
                                ) : null}
                            </div>
                        </Col>
                        <Col
                            col={{ xs: 12, md: 6, lg: 6 }}
                            offset={{
                                lg: 1,
                            }}
                            className={styles.content}>
                            <Heading
                                tag={titleTag}
                                className="text-primary-t-500 heading1">
                                {title}
                            </Heading>
                            <Text className="mt-4 mb-4 medium" tag="div">
                                {description}
                            </Text>
                            {React.isValidElement(form) ? form : null}
                        </Col>
                    </Row>
                </BaseCard>
            </Container>
        </Section>
    );
};

export default CardFormSection;
