import React, { memo } from 'react';
import cls from 'clsx';
import style from './style.module.scss';

import {
    Text,
    className as classNameDS,
} from '@solublestudio/react-bootstrap-components';

export default memo(function KPIComponent({
    title,
    value,
    dark,
    withPlus = false,
    className,
    wrapperProps,
    classNameText,
    variant = 'big',
}) {
    return (
        <div
            {...classNameDS(
                cls([
                    style.mainContainer,
                    variant === 'small' && style.small,
                    className ? className : '',
                ]),
            )}
            {...(wrapperProps || {})}>
            <div className={cls([style.container, dark && style.dark])}>
                {withPlus && <div className={style.withPlus} />}
                <div className={style.content}>
                    <Text
                        addBreaklines={false}
                        className={cls([
                            'display2',
                            'font-weight-bold',
                            dark ? 'text-light' : 'text-primary-500',
                            classNameText,
                        ])}>
                        {value}
                    </Text>
                </div>
            </div>
            <div className={style.wrapperSubtitle}>
                <Text
                    tag="p"
                    className={cls([
                        style.subtitle,
                        dark ? 'text-light' : 'text-primary-900',
                    ])}>
                    {title}
                </Text>
            </div>
        </div>
    );
});
