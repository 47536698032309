import React from 'react';

import { Text } from '@solublestudio/react-bootstrap-components';

import cls from 'clsx';

import Card from '../Card';

import Link from '../Link';

import styles from './styles.module.scss';

const BannerBlock = ({
    title,
    pretitle,
    text,
    cta,
    horizontal,
    imgTop,
    withLine,
}) => {
    return (
        <Card
            wrapperClassName={styles.cardWrapper}
            horizontal={horizontal}
            title={title}
            imgTop={imgTop}
            imgTopWrapperClassName={cls(styles['image-wrapper'])}
            text={text}
            isSolution={true}
            titleClassName={`heading2 ${styles.title}`}
            bodyClassName={styles.card}
            textClassName={styles.text}
            bottomWrapperClassName={styles.bottom}
            preTitleComponent={
                <>
                    <Text component="p" className={`caption ${styles.black}`}>
                        {pretitle}
                    </Text>
                </>
            }
            button={{
                label: cta.label,
                href: cta.href,
                Tag: Link,
            }}
            withLine={withLine}
        />
    );
};

export default BannerBlock;
