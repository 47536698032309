import React, { memo } from 'react';

import Card from '../Card';

import style from './style.module.scss';

const CardEbook = (props) => {
    return <Card {...props} bodyClassName={style.body} />;
};

export default memo(CardEbook);
