import React, { useState, useContext, createContext } from 'react';

const Context = createContext({
    progress: 0,
    setProgress: () => {},
});

const ProgressProvider = ({ children }) => {
    const [progress, setProgress] = useState(0);

    return (
        <Context.Provider value={{ progress, setProgress }}>
            {children}
        </Context.Provider>
    );
};

function useProgressBar() {
    const { progress, setProgress } = useContext(Context);

    return [progress, setProgress];
}

const ProgressConsumer = Context.Consumer;

export { useProgressBar, ProgressProvider, ProgressConsumer };
