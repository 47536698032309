import React, { useEffect, useState, useRef, useMemo } from 'react';
import cls from 'clsx';
import styles from './styles.module.scss';
import {
    Slider,
    Container,
    Row,
    Col,
    className,
} from '@solublestudio/react-bootstrap-components';

export default function Carousel({ children, items = 1, desktopItems = 2 }) {
    const sliderRef = useRef();
    const [isFirst, setIsFirst] = useState(true);
    const [isLast, setIsLast] = useState(false);
    const [drag, setDrag] = useState(false);

    const sliderSettings = useMemo(
        () => ({
            controls: false,
            loop: false,
            nav: true,
            navPosition: 'bottom',
            mouseDrag: true,
            edgePadding: 20,
            gutter: 24,
            items,
            responsive: {
                576: {
                    edgePadding: 135,
                },
                992: {
                    items: desktopItems,
                    edgePadding: 270,
                },
                1275: {
                    items: desktopItems,
                    edgePadding: 228,
                },
                1435: {
                    items: desktopItems,
                    edgePadding: 333,
                },
                1740: {
                    items: desktopItems,
                    edgePadding: 533,
                },
                2140: {
                    items: desktopItems,
                    edgePadding: 733,
                },
            },
        }),
        [desktopItems, items],
    );

    useEffect(() => {
        const configureClasses = (slider) => {
            setIsFirst(slider.index === 0 ? true : false);
            const slidesNumber = Math.ceil(slider.slideCount / slider.items);
            setIsLast(slider.index === slidesNumber ? true : false);
        };

        if (sliderRef.current) {
            sliderRef.current.slider.events.on(
                'indexChanged',
                configureClasses,
            );

            sliderRef.current.slider.events.on('dragEnd', () => setDrag(false));
            sliderRef.current.slider.events.on('dragMove', () => setDrag(true));
        }

        return () => {
            if (sliderRef.current) {
                sliderRef.current.slider.events.off(
                    'indexChanged',
                    configureClasses,
                );
                sliderRef.current.slider.events.off('dragEnd', () =>
                    setDrag(false),
                );
                sliderRef.current.slider.events.off('dragMove', () =>
                    setDrag(true),
                );
            }
        };
    }, [sliderRef, setIsFirst, setIsLast]);

    return children ? (
        <div className={styles.wrapper}>
            <Container>
                {children.length <= desktopItems ? (
                    <Row
                        className={
                            children.length > 1 ? 'd-none d-lg-flex' : ''
                        }
                        pt={{ xs: 3, lg: 5 }}
                        pb={{ xs: 3, lg: 5 }}>
                        {children.map((ch, i) => (
                            <Col
                                key={i}
                                col={{ lg: desktopItems > 1 ? 6 : 12 }}>
                                {ch}
                            </Col>
                        ))}
                    </Row>
                ) : null}
                {children.length > 1 ? (
                    <div
                        {...className(
                            cls(
                                styles.slider,
                                isFirst ? styles.sliderInFirst : null,
                                isLast ? styles.sliderInLast : null,
                                children.length <= desktopItems
                                    ? 'd-lg-none'
                                    : null,
                                drag ? styles.sliderDrag : null,
                            ),
                        )}>
                        {children ? (
                            <Slider
                                parentRef={sliderRef}
                                settings={sliderSettings}>
                                {children}
                            </Slider>
                        ) : null}
                    </div>
                ) : null}
            </Container>
        </div>
    ) : null;
}
