import React from 'react';
import cls from 'clsx';

import style from './style.module.scss';

export default function HTMLTextComponent({
    text,
    colorScheme,
    htmlClassName,
    dataCheckList = true,
    dataLink = true,
}) {
    return (
        <div
            {...(dataCheckList && { 'data-checklist': 'true' })}
            {...(dataLink && { 'data-link': 'true' })}
            className={cls(
                style.HTMLTextComponent,
                colorScheme && style[colorScheme],
                htmlClassName,
            )}
            dangerouslySetInnerHTML={{
                __html: text,
            }}
        />
    );
}
